import React, { useMemo, lazy, Suspense } from "react";
import { Tabs } from "uikit";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../../../redux/store";
import useActiveTab from "../../../../../../hooks/useActiveTab";
import { hasAccess } from "../../../../../../access";
import { TabOptions } from "../../../../../../types";
import BasicPageLayout from "../../../../../../components/BasicPageLayout";
import { SuspenseLoader } from "../../../../../../components/common";

import { TabKeys, TabAccessNames } from "./constants/access";

const ExpandableTabs = styled(Tabs)`
	& > * {
		white-space: normal;
		word-wrap: break-word;
		height: auto;
		padding: 11px 10px;
	}
`;

const LazyCashlessOrders = lazy<React.FC<AccountingReports.Props>>(async () => {
	const elem = await import(
		"./tabs/CashlessOrders" /* webpackChunkName: "accounting-reports-cashless-orders" */
	);
	return elem;
});
const LazyCashlessOrdersPeriodReport = lazy<React.FC<any>>(async () => {
	const elem = await import(
		"./tabs/CashlessOrdersPeriodReport" /* webpackChunkName: "accounting-reports-cashless-orders-period-report" */
	);
	return elem;
});
const LazyFuelDeductionOrdersReport = lazy<React.FC<any>>(async () => {
	const elem = await import(
		"./tabs/FuelDeductionOrdersReport" /* webpackChunkName: "accounting-reports-fuel-deduction-orders-report" */
	);
	return elem;
});
const LazyAgentOrdersReport = lazy<React.FC<any>>(async () => {
	const elem = await import(
		"./tabs/AgentOrdersReport" /* webpackChunkName: "accounting-reports-agent-orders-report" */
	);
	return elem;
});
const LazyAccountsReport = lazy<React.FC<any>>(async () => {
	const elem = await import(
		"./tabs/AccountsReport" /* webpackChunkName: "accounting-reports-accounts-report" */
	);
	return elem;
});

const AccountingReports: React.FC = () => {
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: TabKeys.CASHLESS_ORDERS,
					label: t(
						"mainPage.reports.accounting.cashlessOrders.title",
					),
					value: {
						render: () => (
							<Suspense fallback={<SuspenseLoader />}>
								<LazyCashlessOrders
									headerTitle={t(
										"mainPage.reports.accounting.cashlessOrders.title",
									)}
								/>
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.CASHLESS_ORDERS],
				},
				{
					key: TabKeys.CASHLESS_ORDERS_GENERAL,
					label: t(
						"mainPage.reports.accounting.cashlessOrdersGeneral.title",
					),
					value: {
						render: () => (
							<Suspense fallback={<SuspenseLoader />}>
								<LazyCashlessOrders
									headerTitle={t(
										"mainPage.reports.accounting.cashlessOrdersGeneral.title",
									)}
									general={true}
								/>
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.CASHLESS_ORDERS_GENERAL],
				},
				{
					key: TabKeys.CASHLESS_ORDERS_PERIOD_REPORT,
					label: t(
						"mainPage.reports.accounting.cashlessOrdersPeriodReport.title",
					),
					value: {
						render: () => (
							<Suspense fallback={<SuspenseLoader />}>
								<LazyCashlessOrdersPeriodReport
									headerTitle={t(
										"mainPage.reports.accounting.cashlessOrdersPeriodReport.title",
									)}
								/>
							</Suspense>
						),
					},
					accessName:
						TabAccessNames[TabKeys.CASHLESS_ORDERS_PERIOD_REPORT],
				},
				{
					key: TabKeys.CASHLESS_ACCOUNTS_SUMMARY_REPORT,
					label: t(
						"mainPage.reports.accounting.cashlessAccountsSummaryReport.title",
					),
					value: {
						render: () => (
							<Suspense fallback={<SuspenseLoader />}>
								<LazyCashlessOrdersPeriodReport
									headerTitle={t(
										"mainPage.reports.accounting.cashlessAccountsSummaryReport.title",
									)}
									general={true}
								/>
							</Suspense>
						),
					},
					accessName:
						TabAccessNames[
							TabKeys.CASHLESS_ACCOUNTS_SUMMARY_REPORT
						],
				},
				{
					key: TabKeys.FUEL_DEDUCTION_ORDERS_REPORT,
					label: t(
						"mainPage.reports.accounting.fuelDeductionOrdersReport.title",
					),
					value: {
						render: () => (
							<Suspense fallback={<SuspenseLoader />}>
								<LazyFuelDeductionOrdersReport />
							</Suspense>
						),
					},
					accessName:
						TabAccessNames[TabKeys.FUEL_DEDUCTION_ORDERS_REPORT],
				},
				{
					key: TabKeys.AGENT_ORDERS_REPORT,
					label: t(
						"mainPage.reports.accounting.agentOrdersReport.title",
					),
					value: {
						render: () => (
							<Suspense fallback={<SuspenseLoader />}>
								<LazyAgentOrdersReport
									headerTitle={t(
										"mainPage.reports.accounting.agentOrdersReport.title",
									)}
								/>
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.AGENT_ORDERS_REPORT],
				},
				{
					key: TabKeys.ACCOUNTS_REPORT,
					label: t(
						"mainPage.reports.accounting.accountsReport.title",
					),
					value: {
						render: () => (
							<Suspense fallback={<SuspenseLoader />}>
								<LazyAccountsReport />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.ACCOUNTS_REPORT],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<ExpandableTabs
					value={activeKey}
					onChange={(newActive) => setActiveKey(newActive)}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

declare namespace AccountingReports {
	interface Props {
		headerTitle: string;
		general?: boolean;
	}
}

export default AccountingReports;
